<template>

        <div>
            <div class="m-2">
                <div v-if="module == 'module_agent' && agent.agent_data && agent.agent_data.company != 'Y'" class="mb-1 text-right">
                    <b-button  :href="$base_url  +'documents/download_statement?file=' + doc_statements.document_ud" variant="success"   class="btn btn-primary "><span class="text-nowrap d-inline-block">{{ $t('label_download_the_work_contract_statement') }}</span></b-button>
                    <b-button :href="$base_url  +'documents/download_statement?file=' + doc_statements.document_uz" variant="success"   class="btn btn-primary ml-1"><span class="text-nowrap d-inline-block">{{ $t('label_download_the_contract_agreement_statement') }}</span></b-button>
                </div>
                <b-row>

                    <b-col
                            cols="12"
                            md="2"
                            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"

                    >

                        <v-select
                                v-model="tableData.perPage"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="tableData.perPageOptions"
                                :clearable="false"
                                :searchable="false"
                                class="per-page-selector d-inline-block mr-1"
                        >
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>

                        <column-chooser v-model="selectedColumns" :not-touch="['id']"
                                        :columns="columnsDefs"></column-chooser>

                    </b-col>
                    <b-col v-if="module == 'module_agent'"
                            cols="12"
                            md="3"
                            class=" mb-1 date-range-col"
                    >
                        <label>{{ $t('label_status') }}</label>

                        <v-select
                                v-model="filterData.status"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="[{label:'label_paid', alias: 'paid'}, {label: 'label_not_paid', alias: 'not_paid'}]"
                                :reduce="val => val.alias"
                                label="label"
                                :clearable="false"
                                :placeholder="$t('label_select')"
                        >
                            <template v-slot:option="option">
                                {{$t(option.label)}}
                            </template>
                            <template v-slot:selected-option="option">
                                {{$t(option.label)}}
                            </template>
                            <template v-slot:no-options="option">
                                {{$t('label_no_items')}}
                            </template>
                        </v-select>


                    </b-col>

                    <!-- Search -->
                    <b-col
                            cols="12"
                            md="5"
                            class="ml-auto d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >

                        <b-form-input
                                v-model="tableData.searchQuery"
                                type="search"
                                debounce="500"
                                class="d-inline-block mr-1 "
                                :placeholder="$t('label_search')+'...'"
                        />
                        <template v-if="module == 'module_agent'  && filterData.status == 'not_paid' && agent.agent_data && auth_user.id == agent.id">
                            <b-button v-if="agent.agent_data.company == 'Y'" variant="primary"  @click.prevent="$bvModal.show('invoice-modal')" class="btn btn-primary "><span class="text-nowrap">{{ $t('label_add_invoice') }}</span></b-button>
                            <b-button v-else-if="agent.agent_data.company != 'Y' && tableTotal > 0" variant="primary"  @click.prevent="invoiceId = false; uploadedDocType = 'invoice'; $bvModal.show('upload-doc-modal')" class="btn btn-primary "><span class="text-nowrap">{{ $t('label_add_invoice') }}</span></b-button>
                        </template>


                    </b-col>
                </b-row>

            </div>

            <!--***********************************************************TABLE*********************************************-->

            <div class="px-2 table-container-wrap">
                <!--sticky-header-->
                <b-table

                        :ref="'_TABLE'"
                        class="position-relative transited-table styled-footer"
                        :items="tableItems"
                        responsive
                        :busy="isBusy"
                        :fields="selectedColumns"
                        primary-key="id"
                        no-border-collapse
                        :sort-by.sync="tableData.sortBy"
                        no-local-sorting
                        :sort-desc.sync="tableData.sortDesc"
                        show-empty
                        :foot-clone="true"
                        :tbody-transition-props="{ name: 'flip-list'}"
                        :empty-text="$t('label_no_table_data')"
                >

                    <template #head()="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() }}</span>
                    </template>
                    <template #head(agent_provision_percent)="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() + ', %' }}</span>
                    </template>
                    <template #head(porper_provision_percent)="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() + ', %' }}</span>
                    </template>
                    <template #head(invoice_details)="data">
                        <span class="text-secondary">{{ $t(data.label).ucFirst() + '/FV' }}</span>
                    </template>

                    <template #cell(agent_id)="data">

                        <router-link v-if="$can('view', 'agent.tab_details')" :to="{ name: 'agent-details', params: { id: data.item.agent.id } }">
                            {{data.item.agent.name }}
                        </router-link>
                        <span v-else>{{data.item.agent.name }}</span>
                    </template>

                    <template #cell(payment_type)="data">

                        <!--<router-link v-if="$can('view', 'agent.tab_details')" :to="{ name: 'agent-details', params: { id: data.item.agent.id } }">-->
                            <!--{{data.item.agent.name }}-->
                        <!--</router-link>-->
                        <span v-if="data.item.agent_invoice">{{data.item.agent_invoice.pament_type }}</span>
                        <span v-else>--</span>
                    </template>

                    <template #cell(agreement_deal_number)="data">

                        <template v-if="data.item.agreement">
                            <router-link  v-if="data.item.agreement.is_deal && data.item.agreement.deal && $can('view', 'deal.tab_details')"
                                         :to="{name: 'deal-details', params:{id:data.item.agreement.deal.id}}">
                                {{data.item.agreement.deal_number}}
                            </router-link>
                            <router-link  v-else-if="data.item.agreement &&  $can('view', 'agreement.tab_details')"
                                         :to="{name: 'agreement-details', params:{id:data.item.agreement.id}}">
                                {{data.item.agreement.deal_number}}
                            </router-link>
                            <template v-else>
                                {{data.item.agreement.deal_number}}
                            </template>
                        </template>
                        <span v-else>--</span>

                    </template>

                    <template #cell(invoice_details)="data">

                            <div v-if="data.item.agent_invoice"   class="text-center" >
                            <b-button  v-if="data.item.agent.userType_data && data.item.agent.userType_data.company ==  'Y'" variant="outline-success" size="sm"  :to="{name: 'agent_invoice', params:{id:data.item.agent_id, invoice_id:data.item.agent_invoice.id}}">
                                <feather-icon
                                        size="18"
                                        icon="FileIcon"
                                />
                            </b-button>


                               <a v-else-if="data.item.agent_invoice.agent_document"
                                   class="preview-firstly" @click="previewFile($event, 'documents/download/', data.item.agent_invoice.agent_document)" href="#"

                               >
                                   <feather-icon
                                           size="18"
                                           icon="FileIcon"
                                   />
                               </a>
                           </div>

                    </template>

                    <template #cell(invoice_number)="data">

                        <template v-if="data.item.agent.userType_data">
                            <span v-if="data.item.agent.userType_data.company == 'Y'">{{data.item.agent_invoice.invoice_no}}</span>
                            <template v-else-if="module == 'module_agent' && data.item.agent.userType_data.company != 'Y'">

                                <span v-if="!data.item.agent_invoice.admin_document">{{$t('label_wait_for_bill')}}</span>
                                <b-button v-else
                                          variant="outline-info"
                                          class="preview-firstly" @click="previewFile($event, 'documents/download/', data.item.agent_invoice.admin_document)" href="#"
                                          icon
                                >
                                    <feather-icon
                                            size="18"
                                            icon="DownloadIcon"
                                    />
                                </b-button>
                            </template>

                            <template v-else-if="module == 'module_admin'">

                                <b-button
                                        v-if="data.item.agent.userType_data.company != 'Y' && !data.item.agent_invoice.admin_document"
                                        variant="outline-primary"
                                        class=" btn-sm-block"
                                        @click="invoiceId = data.item.agent_invoice.id; uploadedDocType = 'admin_bill'; $bvModal.show('upload-doc-modal')"

                                        size="sm"
                                >
                                    {{$t('label_add_bill')}}
                                </b-button>
                                <b-button v-else
                                          variant="outline-info"
                                          class="preview-firstly" @click="previewFile($event, 'documents/download/', data.item.agent_invoice.admin_document)" href="#"
                                          icon
                                >
                                    <!--:to="{path: $domain + 'download/' + data.item.agent_invoice.admin_document}"-->
                                    <feather-icon
                                            size="18"
                                            icon="DownloadIcon"
                                    />
                                </b-button>

                            </template>
                        </template>

                    </template>


                    <template #cell(status)="data">

                        <template v-if="data.item.agent_invoice" >

                            <template v-if="module == 'module_admin'">
                                <span v-if="data.item.agent_invoice.paid_status == 'Y'" class="badge badge-success">{{$t('label_paid')}}</span>
                                <b-button v-else
                                        variant="outline-primary"
                                        class=" btn-sm-block"
                                        @click="changeAgentPaymentStatus(data.item.agent_invoice);"

                                        size="sm"
                                >{{$t('label_pay')}}</b-button>
                            </template>

                            <template v-else-if="module == 'module_agent'">
                                <span v-if="data.item.agent_invoice.paid_status == 'Y'" class="badge badge-success">{{$t('label_paid')}}</span>
                                <span v-else class="badge badge-danger">{{$t('label_not_paid')}}</span>
                            </template>

                        </template>

                    </template>


                    <template #foot()="data">
                        <i>{{ '' }}</i>
                    </template>
                    <template #foot(postedtime)="data">
                        <span class="badge badge-light-danger justify-content-center d-flex align-items-center">{{$t('label_sum')}}</span>
                    </template>
                    <template #foot(porper_provision_value)="data">
                        <span class="justify-content-center d-flex align-items-center">{{(summaryData.sum_netto?summaryData.sum_netto : '0')}}</span>
                    </template>



                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>

                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <span class="text-muted">{{ $t('label_showed') + ' '+ $t('label_from').toLowerCase() + ' ' + dataMeta.from + ' ' + $t('label_to_date') + ' ' + dataMeta.to + ' ' + $t('label_of') + ' ' + dataMeta.of }} </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >

                            <b-pagination
                                    v-model="tableData.currentPage"
                                    :total-rows="tableTotal"
                                    :per-page="tableData.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>

            </div>
            <template v-if="module == 'module_agent' && auth_user.role == 'agent' && agentId == auth_user.id && agent.agent_data">
                <process-invoice-modal v-if="agent.agent_data.company == 'Y'" :client="agent" @item-added="refreshDataTable()"  ></process-invoice-modal>
            </template >
            <upload-doc-modal :item-id="invoiceId" :doc-type="uploadedDocType" v-if="!agent.agent_data || (agent.agent_data && agent.agent_data.company != 'Y')" :client="agent" @item-added="refreshDataTable()" ></upload-doc-modal>

        </div>

</template>

<script>

    import {
        BCard, BButton, BTable, BMedia, BAvatar, BLink, BFormInput,
        BBadge, BDropdown, BDropdownItem, BPagination, BCardBody, BCardHeader, BFormCheckbox, BTooltip
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'
    import columnChooser from '@/views/components/columnChooser'
    import processInvoiceModal from '../modals/processInvoiceModal'
    import uploadDocModal from '../modals/uploadDocModal'
    import infinityScroll from '@/views/components/infinityScroll'

    // import {A_INVOICE_PREFIX as PREFIX} from './../moduleHelper'

    export default {

        components: {
            BCard,
            BCardBody,
            BButton,
            BTable,
            BMedia,
            BAvatar,
            BLink,
            BBadge,
            BDropdown,
            BDropdownItem,
            BPagination,
            BFormCheckbox,
            BCardHeader,
            BTooltip,
            BFormInput,
            columnChooser,
            vSelect,
            infinityScroll,
            processInvoiceModal,
            uploadDocModal
        },

        props:['extraFilterData', 'agentId', 'module'],

        data() {

            return {

                selectedColumns: [],

                columnsDefs: [],
                adminColumnsDefs: [
                    {label: 'label_provision_date', key: 'postedtime',formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }, sortable: false},
                    {label: 'label_agent', key: 'agent_id', sortable: false},
                    {label: 'label_payment_amount_gross', key: 'provision_gross', sortable: false},
                    {label: 'label_provision_gross', key: 'porper_provision_value', sortable: false},
                    {label: 'label_deal_number', key: 'agreement_deal_number', sortable: false},
                    {label: 'label_invoice', key: 'invoice_details', sortable: false},
                    {label: 'label_invoice_number', key: 'invoice_number', sortable: false},
                    {label: 'label_status', key: 'status'}
                ],
                columnsAgentCompanyDefs: [
                    {label: 'label_provision_date', key: 'postedtime',formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }, sortable: false},
                    {label: 'label_deal_number', key: 'agreement_deal_number', sortable: false},
                    {label: 'label_provision', key: 'agent_provision_percent', sortable: false},
                    {label: 'label_proper_provision', key: 'porper_provision_percent', sortable: false},
                    {label: 'label_company_provision', key: 'provision_gross',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                    {label: 'label_value', key: 'porper_provision_value',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                ],
                columnsAgentCompanyPaidDefs: [
                    {label: 'label_provision_date', key: 'postedtime',formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }, sortable: false},
                    {label: 'label_deal_number', key: 'agreement_deal_number', sortable: false},
                    {label: 'label_payment_type', key: 'payment_type', sortable: false},
                    {label: 'label_provision', key: 'agent_provision_percent', sortable: false},
                    {label: 'label_proper_provision', key: 'porper_provision_percent', sortable: false},
                    {label: 'label_company_provision', key: 'provision_gross',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                    {label: 'label_value', key: 'porper_provision_value',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                    {label: 'label_statement', key: 'invoice_details', sortable: false},
                    {label: 'label_bill', key: 'invoice_number', sortable: false},
                    {label: 'label_status', key: 'status'}
                ],
                columnsAgentPersonDefs: [
                    {label: 'label_provision_date', key: 'postedtime',formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }, sortable: false},
                    {label: 'label_deal_number', key: 'agreement_deal_number', sortable: false},
                    {label: 'label_provision', key: 'agent_provision_percent', sortable: false},
                    {label: 'label_proper_provision', key: 'porper_provision_percent', sortable: false},
                    {label: 'label_company_provision', key: 'provision_gross',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                    {label: 'label_value', key: 'porper_provision_value',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                ],
                columnsAgentPersonPaidDefs: [
                    {label: 'label_provision_date', key: 'postedtime',formatter: function (v) {
                            return formatDate(v, 'DD.MM.YYYY', 'unix');
                        }, sortable: false},
                    {label: 'label_deal_number', key: 'agreement_deal_number', sortable: false},
                    {label: 'label_payment_type', key: 'payment_type', sortable: false},
                    {label: 'label_payment_type', key: 'agreement_deal_number', sortable: false},
                    {label: 'label_provision', key: 'agent_provision_percent', sortable: false},
                    {label: 'label_proper_provision', key: 'porper_provision_percent', sortable: false},
                    {label: 'label_company_provision', key: 'provision_gross',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                    {label: 'label_value', key: 'porper_provision_value',formatter: function (v) {
                            return  v? v.formatPrice() : '';
                        }, sortable: false},
                    {label: 'label_statement', key: 'invoice_details', sortable: false},
                    {label: 'label_bill', key: 'invoice_number', sortable: false},
                    {label: 'label_status', key: 'status'}
                ],
                isBusy: false,
                tableTotal: 0,
                tableItems: [],

                tableData: {
                    currentPage: 1,
                    perPage: 25,
                    searchQuery: '',
                    sortBy: 'id',
                    sortDesc: true,
                    perPageOptions: [10, 25, 50, 100],
                },

                filterData: {
                  status: 'not_paid'
                },
                summaryData:{sum_netto:0},
                auth_user: {},
                agent:{},
                doc_statements: {},
                uploadedDocType: '',
                invoiceId: false
            }
        },

        watch: {
            tableData: {
                handler: function (newVal, old) {
                    let self = this;
                    self.refreshTableId = setTimeout(function(){
                        clearInterval(self.refreshTableId);
                        self.refreshDataTable();
                    },100);
                },
                deep: true
            },
            filterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            },
            extraFilterData: {
                handler: function (old, newVal) {
                    this.refreshDataTable();
                },
                deep: true
            }
        },

        created() {

            // let allowed = this.columnsDefs.filter((el) => {
            //     if (el.key == 'actions' && !this.$can('edit', 'agreement.tab_procurations')) {
            //         return false;
            //     }
            //     return true;
            // });

            this.auth_user = this.authUser();

            if(this.module == 'module_agent') {
                this.async('get', '/agent/' + this.agentId , {params:{}}, function(res){

                    this.agent = res.data;

                    this.refreshDataTable();
                });
                this.async('get', '/documents/statements', {params: {}}, function (res) {
                    this.doc_statements = res.data;
                });
            } else {
                this.refreshDataTable();
            }

        },
        computed: {
            dataMeta: function(){
                return {
                    from: this.tableData.perPage * (this.tableData.currentPage - 1) + (this.tableItems.length ? 1 : 0),
                    to: this.tableData.perPage * (this.tableData.currentPage - 1) + this.tableItems.length,
                    of: this.tableTotal,
                };
            },
        },

        methods: {

            changeAgentPaymentStatus(invoice){
                this.confirmAction(this.$t('label_status'), this.$t('label_are_you_sure_you_want_to_done_it'),'warning',(result) => {
                    this.async('put', '/agent_invoice/paid/' + invoice.id, {params:{}}, function (resp) {
                        this.refreshDataTable();
                    });
                });
            },

            refreshSummary(){
                let params = {};
                if(this.extraFilterData){
                    params.status_id = this.extraFilterData.status_id;
                }
                if(this.agentId) {
                    params.agent_id = this.agentId;
                }
                if(this.module == 'module_agent') {
                    if(this.filterData.status == 'not_paid'){
                        params.status = 'notpaid';
                    } else if(this.filterData.status == 'paid'){
                        params.status = 'paid';
                    }
                }
                this.async('get', '/due_law_provisions/total', {params: params}, function(res){
                    this.summaryData.sum_netto = res.data.total.formatPrice();
                });
            },

            refreshDataTable: function () {

                this.isBusy = true;

                let params =  {
                    length: this.tableData.perPage,
                    start: (this.tableData.currentPage - 1) * this.tableData.perPage,
                    search: this.tableData.searchQuery,
                    sort_by: this.tableData.sortBy,
                    sort_desc: this.tableData.sortDesc? true : null,
                };

                if(this.extraFilterData) {
                    params.status_id = this.extraFilterData.status_id;
                }
                if(this.agentId) {
                    params.agent_id = this.agentId;
                }

                if(this.module == 'module_agent') {

                    if(this.filterData.status == 'not_paid'){
                        params.status = 'notpaid';
                        this.columnsDefs = this.agent.agent_data.company == 'Y'?  this.columnsAgentCompanyDefs : this.columnsAgentPersonDefs;
                    } else if( this.filterData.status == 'paid') {
                        params.status = 'paid';
                        this.columnsDefs = this.agent.agent_data.company == 'Y'?  this.columnsAgentCompanyPaidDefs : this.columnsAgentPersonPaidDefs;
                    }
                } else if(this.module == 'module_admin'){
                    params.status = 'paid';
                    this.columnsDefs = this.adminColumnsDefs;
                }

                this.async('get', '/due_law_provisions', {
                    params:params
                }, function (resp) {

                    this.tableItems = resp.data.items;
                    this.tableTotal = resp.data.total;

                    this.isBusy = false;
                });

                this.refreshSummary();
            },

        },

    }
</script>
