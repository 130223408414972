export const MODULE_PREFIX = 'agent';
export const DEPARTMENT_PREFIX = 'department';
export const LANGUAGES_PREFIX = 'alllanguage';
export const PROVISION_PREFIX = 'provision';
export const NOTE_PREFIX = 'note';
export const HISTORY_PREFIX = 'history';
export const DOCUMENT_PREFIX = 'document';
export const PAYMENT_PREFIX = 'payment';
export const USER_PREFIX = 'user';
export const MLM_PREFIX = 'mlm';
export const INVOICE_PREFIX = 'invoice';

