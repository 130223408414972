<template>

    <component :is="tag? tag : 'div'">

        <slot name="prepend_content"></slot>

        <template v-if="tag === 'tbody'">


            <single-item-form tag="tr" class="mt-2" v-if="items && items.length > 0"  @delete-item="$emit('deleteItemServer',$event)" :item="serverItem" :ind="ind" v-for="serverItem, ind in items" :key="ind">
                <template #form="{item, ind, deleteItem}">
                    <slot :deleteItem="deleteItem" :item="item" :ind="ind" name="form"></slot>
                </template>
            </single-item-form>

            <single-item-form tag="tr"  @delete-item="deleteBlankItem($event)" :item="new_item" :ind="ind" v-for="new_item, ind in newItems" :key="'new_' + ind">
                <template #form="{item, ind, deleteItem}">
                    <slot :deleteItem="deleteItem" :item="item" :ind="ind" name="form"></slot>
                </template>
            </single-item-form>


           <slot name="button" :addBlankItem="addBlankItem">
               <tr class="mb-3">
                  <td>
                      <b-button @click.prevent="addBlankItem()"
                                class=" btn btn-success  ">
                          {{$t('label_add_another_representative')}}
                      </b-button>
                  </td>
               </tr>
           </slot>


        </template>

       <template v-else>

           <b-form-group
                   v-if="!noSwitch"
                   label=""
           >
               <b-form-checkbox
                       switch
                       :checked="showItemsSection"
                       @input="$emit('update:showItemsSection', $event)"
               >
                   <label >{{ $t(label) }}</label>
               </b-form-checkbox>
           </b-form-group>

           <div :class="alias +'-items-block'" v-if="showItemsSection">
               <!--addedOnServer-->
               <template class="mt-2" v-if="items && items.length > 0">
                   <single-item-form @delete-item="$emit('deleteItemServer',$event)" :item="serverItem" :ind="ind" v-for="serverItem, ind in items" :key="ind">
                       <template #form="{item, ind, deleteItem}">
                           <slot :deleteItem="deleteItem" :item="item" :ind="ind" name="form"></slot>
                       </template>
                   </single-item-form>
               </template>
               <!--New-->
               <single-item-form @delete-item="deleteBlankItem($event)" :item="new_item" :ind="ind" v-for="new_item, ind in newItems" :key="'new_' + ind">
                   <template #form="{item, ind, deleteItem}">
                       <slot :deleteItem="deleteItem" :item="item" :ind="ind" name="form"></slot>
                   </template>
               </single-item-form>

               <slot name="button" :addBlankItem="addBlankItem">
                   <div class="mb-3">
                       <b-button @click.prevent="addBlankItem()"
                                 class=" btn btn-success  ">
                           {{$t('label_add_another_representative')}}
                       </b-button>
                   </div>
               </slot>


           </div>
       </template>


    </component>

</template>

<script>
    import singleItemForm from './singleItemForm'

    import {
        BButton, BFormGroup, BFormCheckbox
    } from 'bootstrap-vue'

    export default {
        components: {
            singleItemForm,
            // singleTrItemForm,
            BButton, BFormGroup, BFormCheckbox
        },
        props: ['tag', 'items', 'label', 'alias', 'blankItem', 'showItemsSection', 'newItems', 'noSwitch', 'deleteEmptyOnEdit'],
        data() {
            return {
            }
        },

        watch: {
            showItemsSection: function (newVal, old) {
                if (newVal === false) {
                    this.$emit('update:newItems', []);
                    // this.$emit('update:items', []);
                } else {
                    if((!this.items || (this.items && this.items.length < 1)) && (!this.newItems || (this.newItems && this.newItems.length < 1))) {
                        this.addBlankItem();
                    }
                }
            },
            items: function (newVal, old) {console.log('items',newVal);
                if(this.deleteEmptyOnEdit !== false && newVal && newVal.length > 0 && this.newItems && this.newItems.length == 1){
                    this.deleteBlankItem(0);
                }
                if(!newVal || newVal.length == 0){
                    this.$emit('update:showItemsSection', false)
                }
            }
        },

        methods: {


            addBlankItem() {
                this.newItems.push(this.blankItem);

            },
            deleteBlankItem(ind) {
                this.newItems.splice(ind, 1);
                if(this.newItems.length == 0 && !this.items || (this.newItems.length == 0 && this.items && this.items.length < 1)){
                    this.$emit('update:showItemsSection', false)
                }
            },

        },
        created(){
            if(this.showItemsSection && this.newItems.length < 1) {
                this.addBlankItem();
            }
        }


    }
</script>


