var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag? _vm.tag : 'div',{tag:"component"},[_vm._t("prepend_content"),(_vm.tag === 'tbody')?[_vm._l((_vm.items),function(serverItem,ind){return (_vm.items && _vm.items.length > 0)?_c('single-item-form',{key:ind,staticClass:"mt-2",attrs:{"tag":"tr","item":serverItem,"ind":ind},on:{"delete-item":function($event){return _vm.$emit('deleteItemServer',$event)}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var item = ref.item;
var ind = ref.ind;
var deleteItem = ref.deleteItem;
return [_vm._t("form",null,{"deleteItem":deleteItem,"item":item,"ind":ind})]}}],null,true)}):_vm._e()}),_vm._l((_vm.newItems),function(new_item,ind){return _c('single-item-form',{key:'new_' + ind,attrs:{"tag":"tr","item":new_item,"ind":ind},on:{"delete-item":function($event){return _vm.deleteBlankItem($event)}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var item = ref.item;
var ind = ref.ind;
var deleteItem = ref.deleteItem;
return [_vm._t("form",null,{"deleteItem":deleteItem,"item":item,"ind":ind})]}}],null,true)})}),_vm._t("button",[_c('tr',{staticClass:"mb-3"},[_c('td',[_c('b-button',{staticClass:" btn btn-success  ",on:{"click":function($event){$event.preventDefault();return _vm.addBlankItem()}}},[_vm._v(" "+_vm._s(_vm.$t('label_add_another_representative'))+" ")])],1)])],{"addBlankItem":_vm.addBlankItem})]:[(!_vm.noSwitch)?_c('b-form-group',{attrs:{"label":""}},[_c('b-form-checkbox',{attrs:{"switch":"","checked":_vm.showItemsSection},on:{"input":function($event){return _vm.$emit('update:showItemsSection', $event)}}},[_c('label',[_vm._v(_vm._s(_vm.$t(_vm.label)))])])],1):_vm._e(),(_vm.showItemsSection)?_c('div',{class:_vm.alias +'-items-block'},[(_vm.items && _vm.items.length > 0)?_vm._l((_vm.items),function(serverItem,ind){return _c('single-item-form',{key:ind,attrs:{"item":serverItem,"ind":ind},on:{"delete-item":function($event){return _vm.$emit('deleteItemServer',$event)}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var item = ref.item;
var ind = ref.ind;
var deleteItem = ref.deleteItem;
return [_vm._t("form",null,{"deleteItem":deleteItem,"item":item,"ind":ind})]}}],null,true)})}):_vm._e(),_vm._l((_vm.newItems),function(new_item,ind){return _c('single-item-form',{key:'new_' + ind,attrs:{"item":new_item,"ind":ind},on:{"delete-item":function($event){return _vm.deleteBlankItem($event)}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var item = ref.item;
var ind = ref.ind;
var deleteItem = ref.deleteItem;
return [_vm._t("form",null,{"deleteItem":deleteItem,"item":item,"ind":ind})]}}],null,true)})}),_vm._t("button",[_c('div',{staticClass:"mb-3"},[_c('b-button',{staticClass:" btn btn-success  ",on:{"click":function($event){$event.preventDefault();return _vm.addBlankItem()}}},[_vm._v(" "+_vm._s(_vm.$t('label_add_another_representative'))+" ")])],1)],{"addBlankItem":_vm.addBlankItem})],2):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }